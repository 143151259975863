import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Badge, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
import moment from "moment"

import { adminAllDonation, getSupportTickets } from "store/actions"
//componets
import MyPagination from "components/Common/MyPagination"
//css
import "assets/scss/datatables.scss"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

const AllSupportTicket = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useState(1)

  const [limit] = useState(10)

  const { allSupportTicket, loading, language } = useSelector(state => ({
    allSupportTicket: state?.SupportTickets?.allSupportTicket,
    loading: state.Transactions.loading,
    language: state.Layout.language,
  }))

  console.log(allSupportTicket)

  //pages
  const totalPages = Math.ceil(allSupportTicket?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getSupportTickets(searchText, pageSend()))
  }, [dispatch, page, searchText])

  const columns = [
    {
      dataField: "no",
      text: language === "mal" ? "നo." : "No.",
    },
    {
      dataField: "family_member_name",
      text: ` ${
        language === "mal" ? "കുടുംബത്തിലെ അംഗം" : "Family Member Name"
      }`,
    },
    {
      dataField: "mahall_name",
      text: ` ${language === "mal" ? "മഹല്ലിന്റെ പേര്" : "Mahall Name"}`,
    },
    {
      dataField: "date",
      text: ` ${language === "mal" ? "തീയതി" : "Date"}`,
    },
    {
      dataField: "subject",
      text: ` ${language === "mal" ? "വിഷയം" : "Subject"}`,
    },

    {
      dataField: "status",
      text: ` ${language === "mal" ? "പദവി" : "Status"}`,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const handleStatus = status => {
    switch (status) {
      case "pending":
        return "warning"
      case "closed":
        return "danger"
      case "proccessing":
        return "success"
      default:
        return "info"
    }
  }

  const adminData = map(allSupportTicket?.results, (item, index) => ({
    ...item,
    key: index,
    no: <span>{item?.auto_id}</span>,
    date: moment(item?.date).format("DD/MM/YYYY - h:mm A"),
    status: (
      <Badge
        className={"px-2 py-2 badge-soft-" + `${handleStatus(item?.status)}`}
      >
        {item?.status}
      </Badge>
    ),

    action: (
      <Link to={`/support-ticket/update/${item?.id}`} target="_blank">
        <Button type="button" color="light" className=" btn w-xs btn-sm">
          {language === "mal" ? "പ്രവർത്തനം കാണുക" : "View Activity"}
        </Button>
      </Link>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const debounceEnquirySearch = debounce(value => setSearchText(value), 600)

  // const handleSearch = e => {
  //   setSearchText(e.target.value)
  // }

  return (
    <React.Fragment>
      <Row>
        <Col sm="12" md="12" lg="12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={adminData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e =>
                                debounceEnquirySearch(e.target.value)
                              }
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-wrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllSupportTicket
