import React, { Fragment, useState, useEffect } from "react"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Spinner,
  Badge,
  Table,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
//actions
import { Image } from "react-bootstrap"

import { handleFinancialYear } from "../financialYear"

import { deleteMahall, getMahallDetail } from "store/actions"
//componetns
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import logo from "assets/images/product.jpeg"
import UiModalClearAccounts from "./UiModalClearAccounts"

const MahallDetails = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { mahallDetail, loading, detailLoading } = useSelector(state => ({
    loading: state.Mahalls.loading,
    detailLoading: state.Mahalls.detailLoading,
    mahallDetail: state.Mahalls.mahallDetail,
  }))

  const [isOpen, setIsOpen] = useState(false)
  const [accountClearOpen, setAccountClearOpen] = useState(false)

  const handleDelete = () => {
    setIsOpen(true)
  }
  const handleDeleteEvent = () => {
    dispatch(deleteMahall(mahallDetail.id, history))
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(getMahallDetail(params.id))
  }, [])

  const { finalToDate, fromDate } = handleFinancialYear(
    mahallDetail?.financial_year_starting_month
  )

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />

      <MetaTags>
        <title>Mahall | Khaf </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="All Mahalls" breadcrumbItem="Mahall" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col md="12" lg="12">
                <Card>
                  <CardBody>
                    <Badge className="rounded-pill bg-dark font-size-14">
                      {mahallDetail?.custom_id}
                    </Badge>
                    {detailLoading ? (
                      <Spinner />
                    ) : (
                      <Row>
                        <Col md={6}>
                          <div>
                            <div className="d-flex">
                              <h5 className="font-size-22 font-weight-bold mt-1">
                                {mahallDetail?.mahall_english_name}
                              </h5>
                              <div className="mx-3 mt-1">
                                <Badge className="me-1 badge-soft-primary ms-1">
                                  {mahallDetail?.version}
                                </Badge>
                                <Badge className="me-1 badge-soft-primary ms-1">
                                  {mahallDetail?.register_number}
                                </Badge>
                              </div>
                            </div>

                            <p className="mt-2">
                              <i className="bx bx-map-pin text-primary me-1" />{" "}
                              {mahallDetail?.place}, {mahallDetail?.post_office}{" "}
                              Post - {mahallDetail?.pin_code},{" "}
                              {mahallDetail?.district_english_name},{" "}
                              {mahallDetail?.state_english_name}
                            </p>

                            <p>
                              <i className="bx bx-phone text-primary me-1" />{" "}
                              {mahallDetail?.phone_number || ""}
                            </p>
                            <p>
                              <i className="bx bx-envelope text-primary me-1" />{" "}
                              {mahallDetail?.email || ""}
                            </p>
                            <h5 className="font-size-14 font-weight-bold mt-4">
                              Details:
                            </h5>
                          </div>
                          <div className="table-responsive mt-4">
                            <Table className="table mb-0">
                              <tbody>
                                <tr>
                                  <td> Joined on </td>
                                  <td>
                                    {": "}
                                    {moment(mahallDetail?.date_added).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Established on </td>
                                  <td>
                                    {": "}
                                    {moment(
                                      mahallDetail?.established_date
                                    ).format("DD-MM-YYYY")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Local Body</td>
                                  <td>
                                    {": "}
                                    {mahallDetail?.lsg_english_name}{" "}
                                    {mahallDetail?.lsg_type_english}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Village</td>
                                  <td>
                                    {": "}
                                    {mahallDetail?.village_english_name}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Description</td>
                                  <td>
                                    {": "}
                                    {mahallDetail?.description}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Group</td>
                                  <td>
                                    {": "}
                                    {mahallDetail?.mahallgroup_english_name}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                          <div className="ms-3 mt-4">
                            <span className="btn btn-sm btn-outline-primary" onClick={() => setAccountClearOpen(true)}>Reset accounts & varisankya</span>
                          </div>

                          <UiModalClearAccounts
                            show={accountClearOpen}
                            onCloseclick={() => setAccountClearOpen(false)}
                            data={{ "mahall_english_name": mahallDetail?.mahall_english_name, "id": mahallDetail?.id }}
                            history={history}
                          />

                        </Col>
                        <Col md={6}>
                          <div style={{ marginLeft: "50px" }}>
                            <Image
                              src={
                                mahallDetail?.logo ? mahallDetail?.logo : logo
                              }
                              width="150px"
                              height="150px"
                            />
                            <div className="mt-4"></div>
                              <div className="d-flex flex-wrap justify-content-start">
                                <div className="col-md-3 d-flex flex-wrap">
                                  <div></div>
                                    <Badge className="me-1 rounded-pill bg-success">
                                      {mahallDetail?.families_count || 0}
                                    </Badge>
                                  </div>
                                  <div>
                                    <p>Families</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap"></div>
                                  <div>
                                    <Badge className="me-1 rounded-pill bg-success"></Badge>
                              <div className="d-flex flex-wrap justify-content-start">
                                <div className="col-md-3 d-flex flex-wrap">
                                  <div>
                                    <Badge className="me-1 rounded-pill bg-success">
                                      {mahallDetail?.families_count || 0}
                                    </Badge>
                                  </div>
                                  <div>
                                    <p>Families</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap">
                                  <div>
                                    <Badge className="me-1 rounded-pill bg-success">
                                      {mahallDetail?.familiy_members_count || 0}
                                    </Badge>
                                  </div>
                                  <div>
                                    <p>Members</p>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex flex-wrap  justify-content-start">
                                <div className="col-md-3   d-flex flex-wrap">
                                  <div>
                                    <Badge className="me-1 rounded-pill bg-primary">
                                      {mahallDetail?.no_of_males || 0}
                                    </Badge>
                                  </div>
                                  <div>
                                    <p>Male</p>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap">
                                  <div>
                                    <Badge className="me-1 rounded-pill bg-danger">
                                      {mahallDetail?.no_of_patients || 0}
                                    </Badge>
                                  </div>
                                  <div>
                                    <p>Patients</p>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex flex-wrap justify-content-start">
                                <div className="col-md-3   d-flex flex-wrap ">
                                  <div>
                                    <Badge
                                      className="me-1 rounded-pill"
                                      style={{ backgroundColor: "pink" }}
                                    >
                                      {mahallDetail?.no_of_females || 0}
                                    </Badge>
                                  </div>
                                  <div>
                                    <p>Female</p>
                                  </div>
                                </div>

                                <div className="d-flex flex-wrap">
                                  <div>
                                    <Badge className="me-1 rounded-pill bg-danger">
                                      {mahallDetail?.no_of_senior_citizens || 0}
                                    </Badge>
                                  </div>
                                  <div>
                                    <p>Senior Citizens</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4">
                              <p>
                                <span>Financial Year</span> {":"}{" "}
                                <span className="text-danger">{`${fromDate ? fromDate : ""
                                  }${" "} to ${" "}${finalToDate ? finalToDate : ""
                                  }`}</span>
                              </p>
                              {mahallDetail?.default_varisankya_amount && (
                                <p>
                                  <span>Family Varisangya Amount</span> {":"}{" "}
                                  <span
                                    className="text-primary"
                                    style={{ fontSize: "", fontWeight: "bold" }}
                                  >
                                    {mahallDetail?.default_varisankya_amount}
                                  </span>
                                </p>
                              )}
                              {mahallDetail?.default_familymember_varisankya_amount && (
                                <p>
                                  <span>Member Varisangya Amount</span> {":"}{" "}
                                  <span
                                    className="text-primary"
                                    style={{ fontSize: "", fontWeight: "bold" }}
                                  >
                                    {
                                      mahallDetail?.default_familymember_varisankya_amount
                                    }
                                  </span>
                                </p>
                              )}
                              <p>
                                <span>Varisangya Period</span> {":"}{" "}
                                <span
                                  className="text-primary"
                                  style={{ fontSize: "", fontWeight: "bold" }}
                                >
                                  {mahallDetail?.varisankya_period}
                                </span>
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}

                    <Row className="task-dates">
                      <Col sm="12" xs="12">
                        <div className="d-flex justify-content-end mt-5">
                          <div className="">
                            <Link
                              to={`/mahall/update/${mahallDetail?.id}`}
                              className={`btn btn-primary w-md ${loading && "disabled"
                                } btn-m`}
                            >
                              Update{" "}
                            </Link>
                          </div>
                          <div style={{ position: "relative", left: "10px" }}>
                            <Link
                              style={{ opacity: 0.8 }}
                              to="#"
                              className={`btn btn-danger w-md ${loading && "disabled"
                                }  btn-m`}
                              onClick={handleDelete}
                            >
                              Delete
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default MahallDetails

MahallDetails.propTypes = {
  history: PropTypes.object,
}
