import {
  GET_INSTITUTES_SUCCESS,
  GET_INSTITUTES_FAIL,
  GET_INSTITUTE_DROPDOWN_SUCCESS,
  GET_INSTITUTE_DROPDOWN_FAIL,
  GET_INSTITUTE_DETAIL_SUCCESS,
  GET_INSTITUTE_DETAIL_FAIL,
  CREATE_INSTITUTE_SUCCESS,
  CREATE_INSTITUTE_FAIL,
  UPDATE_INSTITUTE_SUCCESS,
  UPDATE_INSTITUTE_FAIL,
  DELETE_INSTITUTE_SUCCESS,
  DELETE_INSTITUTE_FAIL,
  GET_INSTITUTES,
  GET_INSTITUTE_DROPDOWN,
  GET_INSTITUTE_DETAIL,
  UPDATE_INSTITUTE,
  CREATE_INSTITUTE,
  DELETE_INSTITUTE,
  GET_INSTITUTES_CARDS,
  GET_INSTITUTES_CARDS_SUCCESS,
  GET_INSTITUTES_CARDS_FAIL,
  GET_INSTITUTE_MAHALL_DETAIL,
  GET_INSTITUTE_MAHALL_DETAIL_SUCCESS,
  GET_INSTITUTE_MAHALL_DETAIL_FAIL,
  CLEAR_CREATED_INSTITUTE,
  GET_INSTITUTES_FINANCIAL_YEARS,
  GET_INSTITUTES_FINANCIAL_YEARS_SUCCESS,
  GET_INSTITUTES_FINANCIAL_YEARS_FAIL,
  CLEAR_MAHALL_ACCOUNTS,
  CLEAR_MAHALL_ACCOUNTS_SUCCESS,
  CLEAR_MAHALL_ACCOUNTS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  institutes: [],
  instituteDetail: {},
  instituteMahallDetail: {},
  institutesCards: {},
  searchInstitutes: [],
  error: {},
  loading: false,
  detailLoading: false,
  createdInstitute: {},
  institute_financial_years:{}
}

const Institutes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INSTITUTES:
    case GET_INSTITUTE_MAHALL_DETAIL:
    case GET_INSTITUTES_CARDS:
    case UPDATE_INSTITUTE:
    case CREATE_INSTITUTE:
    case GET_INSTITUTES_FINANCIAL_YEARS:
    case GET_INSTITUTE_DROPDOWN:
      case CLEAR_MAHALL_ACCOUNTS:
      return {
        ...state,
        loading: true,
      }
    case GET_INSTITUTE_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_INSTITUTES_SUCCESS:
      return {
        ...state,
        institutes: action.payload,
        loading: false,
      }

    case GET_INSTITUTES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_INSTITUTE_DROPDOWN_SUCCESS:
      return {
        ...state,
        searchInstitutes: action.payload,
        loading: false,
      }

    case GET_INSTITUTE_DROPDOWN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_INSTITUTES_CARDS_SUCCESS:
      return {
        ...state,
        institutesCards: action.payload,
        loading: false,
      }

    case GET_INSTITUTES_CARDS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_INSTITUTE_SUCCESS:
      return {
        ...state,
        institutes: action.payload,
        createdInstitute: action.payload,
        loading: false,
      }

    case CREATE_INSTITUTE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_INSTITUTE_DETAIL_SUCCESS:
      return {
        ...state,
        instituteDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_INSTITUTE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_INSTITUTE_MAHALL_DETAIL_SUCCESS:
      return {
        ...state,
        instituteMahallDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_INSTITUTE_MAHALL_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_INSTITUTE_SUCCESS:
      return {
        ...state,
        instituteDetail: action.payload,
        loading: false,
      }

    case UPDATE_INSTITUTE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_INSTITUTE_SUCCESS:
      return {
        ...state,
        institutes: action.payload,
        // institutes: state.institutes.filter(
        //   institute => institute.id.toString() !== action.payload.id.toString()
        // ),
        loading: false,
      }

    case DELETE_INSTITUTE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CLEAR_CREATED_INSTITUTE:
      return {
        ...state,
        createdInstitute: {},
        loading: false,
      }
      case GET_INSTITUTES_FINANCIAL_YEARS_SUCCESS:
      return {
        ...state,
        institute_financial_years: action.payload,
        loading: false,
      }

    case GET_INSTITUTES_FINANCIAL_YEARS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

      case CLEAR_MAHALL_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case CLEAR_MAHALL_ACCOUNTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    default:
      return state
  }
}

export default Institutes
