import {
  GET_INSTITUTES,
  GET_INSTITUTES_SUCCESS,
  GET_INSTITUTES_FAIL,
  GET_INSTITUTE_DROPDOWN,
  GET_INSTITUTE_DROPDOWN_SUCCESS,
  GET_INSTITUTE_DROPDOWN_FAIL,
  GET_INSTITUTE_DETAIL,
  GET_INSTITUTE_DETAIL_SUCCESS,
  GET_INSTITUTE_DETAIL_FAIL,
  CREATE_INSTITUTE,
  CREATE_INSTITUTE_SUCCESS,
  CREATE_INSTITUTE_FAIL,
  UPDATE_INSTITUTE,
  UPDATE_INSTITUTE_SUCCESS,
  UPDATE_INSTITUTE_FAIL,
  DELETE_INSTITUTE,
  DELETE_INSTITUTE_SUCCESS,
  DELETE_INSTITUTE_FAIL,
  GET_INSTITUTES_CARDS,
  GET_INSTITUTES_CARDS_SUCCESS,
  GET_INSTITUTES_CARDS_FAIL,
  GET_INSTITUTE_MAHALL_DETAIL,
  GET_INSTITUTE_MAHALL_DETAIL_SUCCESS,
  GET_INSTITUTE_MAHALL_DETAIL_FAIL,
  CLEAR_CREATED_INSTITUTE,
  GET_INSTITUTES_FINANCIAL_YEARS,
  GET_INSTITUTES_FINANCIAL_YEARS_SUCCESS,
  GET_INSTITUTES_FINANCIAL_YEARS_FAIL,
  CLEAR_MAHALL_ACCOUNTS,
  CLEAR_MAHALL_ACCOUNTS_SUCCESS,
  CLEAR_MAHALL_ACCOUNTS_FAIL,
} from "./actionTypes"

export const getInstitutes = (searchText, page, instituteType) => ({
  type: GET_INSTITUTES,
  payload: { searchText, page, instituteType },
})

export const getInstitutesSuccess = institutes => ({
  type: GET_INSTITUTES_SUCCESS,
  payload: institutes,
})
export const getInstitutesFail = error => ({
  type: GET_INSTITUTES_FAIL,
  payload: error,
})

export const getInstituteDropdown = (searchText, institute_type) => ({
  type: GET_INSTITUTE_DROPDOWN,
  payload: { searchText, institute_type },
})

export const getInstituteDropdownSuccess = institutes => ({
  type: GET_INSTITUTE_DROPDOWN_SUCCESS,
  payload: institutes,
})
export const getInstituteDropdownFail = error => ({
  type: GET_INSTITUTE_DROPDOWN_FAIL,
  payload: error,
})

export const getInstituteDetail = instituteId => ({
  type: GET_INSTITUTE_DETAIL,
  instituteId,
})

export const getInstituteDetailSuccess = instituteDetail => ({
  type: GET_INSTITUTE_DETAIL_SUCCESS,
  payload: instituteDetail,
})

export const getInstituteDetailFail = error => ({
  type: GET_INSTITUTE_DETAIL_FAIL,
  payload: error,
})

export const getInstituteMahallDetail = () => ({
  type: GET_INSTITUTE_MAHALL_DETAIL,
  payload: "",
})

export const getInstituteMahallDetailSuccess = instituteMahallDetail => ({
  type: GET_INSTITUTE_MAHALL_DETAIL_SUCCESS,
  payload: instituteMahallDetail,
})

export const getInstituteMahallDetailFail = error => ({
  type: GET_INSTITUTE_MAHALL_DETAIL_FAIL,
  payload: error,
})

export const getInstitutesCards = () => ({
  type: GET_INSTITUTES_CARDS,
})

export const getInstitutesCardsSuccess = institutes => ({
  type: GET_INSTITUTES_CARDS_SUCCESS,
  payload: institutes,
})

export const getInstitutesCardsFail = error => ({
  type: GET_INSTITUTES_CARDS_FAIL,
  payload: error,
})

export const createInstitute = (institute, history) => ({
  type: CREATE_INSTITUTE,
  payload: { institute, history },
})

export const createInstituteSuccess = institute => ({
  type: CREATE_INSTITUTE_SUCCESS,
  payload: institute,
})

export const createInstituteFail = error => ({
  type: CREATE_INSTITUTE_FAIL,
  payload: error,
})

export const updateInstitute = (institute, instituteId, history) => ({
  type: UPDATE_INSTITUTE,
  payload: { institute, instituteId, history },
})

export const updateInstituteSuccess = institute => ({
  type: UPDATE_INSTITUTE_SUCCESS,
  payload: institute,
})

export const updateInstituteFail = error => ({
  type: UPDATE_INSTITUTE_FAIL,
  payload: error,
})

export const deleteInstitute = (instituteId, history, type) => ({
  type: DELETE_INSTITUTE,
  payload: { instituteId, history, type },
})

export const deleteInstituteSuccess = institute => ({
  type: DELETE_INSTITUTE_SUCCESS,
  payload: institute,
})

export const deleteInstituteFail = error => ({
  type: DELETE_INSTITUTE_FAIL,
  payload: error,
})

export const clearCreatedInstitute = () => ({
  type: CLEAR_CREATED_INSTITUTE,
})

export const getInstituteFinancialYears = (searchText, page, instituteType) => ({
  type: GET_INSTITUTES_FINANCIAL_YEARS,
  payload: { searchText, page, instituteType },
})

export const getInstituteFinancialYearsSuccess = financial_years => ({
  type: GET_INSTITUTES_FINANCIAL_YEARS_SUCCESS,
  payload: financial_years,
})
export const getInstituteFinancialYearsFail = error => ({
  type: GET_INSTITUTES_FINANCIAL_YEARS_FAIL,
  payload: error,
})


export const clearMahallAccounts = (id,mahall_english_name) => ({
  type: CLEAR_MAHALL_ACCOUNTS,
  payload: { id,mahall_english_name },
})

export const clearMahallAccountsSuccess = instance => ({
  type: CLEAR_MAHALL_ACCOUNTS_SUCCESS,
  payload: instance,
})
export const clearMahallAccountsFail = error => ({
  type: CLEAR_MAHALL_ACCOUNTS_FAIL,
  payload: error,
})