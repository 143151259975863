/* eslint-disable no-dupe-else-if */
import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_LEDGERS,
  GET_LEDGER_DETAIL,
  CREATE_LEDGER,
  UPDATE_LEDGER,
  DELETE_LEDGER,
  GET_LEDGER_DROPDOWN,
  GET_LEDGERS_FOR_DOWNLOAD,
} from "./actionTypes"
import {
  getLedgersSuccess,
  getLedgersFail,
  getLedgerDetailSuccess,
  getLedgerDetailFail,
  createLedgerSuccess,
  createLedgerFail,
  updateLedgerSuccess,
  updateLedgerFail,
  deleteLedgerSuccess,
  deleteLedgerFail,
  getLedgerDropdownSuccess,
  getLedgerDropdownFail,
  getLedgersForDownloadSuccess,
  getLedgersForDownloadFail,
} from "./actions"

import { get, post, del, patch } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getLedgersAPi = ({
  instituteId,
  searchText,
  page,
  date,
  categoryId,
  instituteType,
}) => {
  return get(
    `/api/v1/institute/institute_ledger/?institute=${
      instituteId ? instituteId : ""
    }&start_date=${date.start_date ? date.start_date : ""}&end_date=${
      date.end_date ? date.end_date : ""
    }&ledger_category_choice_id=${
      categoryId ? categoryId : ""
    }&institute_type=${instituteType ? instituteType : ""}&search=${
      searchText ? searchText : ""
    }&page=${page ? page : 1}`
  )
}
const getLedgersForDownloadAPi = ({
  instituteId,
  searchText,
  date,
  categoryId,
  instituteType,
}) => {
  return get(
    `/api/v1/institute/institute_ledger/?&institute=${
      instituteId ? instituteId : ""
    }&start_date=${date.start_date ? date.start_date : ""}&end_date=${
      date.end_date ? date.end_date : ""
    }&ledger_category_choice_id=${
      categoryId ? categoryId : ""
    }&institute_type=${instituteType ? instituteType : ""}&search=${
      searchText ? searchText : ""
    }`
  )
}
// const getInstituteDetailsAPi = instituteId => {
//   return get(`/api/v1/institute/institute/${instituteId}/`)
// }
const getLedgerDetailsAPi = ledgerId => {
  return get(`/api/v1/institute/institute_ledger/${ledgerId}/`)
}
const getLedgerDropdownApi = ({ instituteId, searchText }) => {
  if (instituteId) {
    return get(
      `/api/v1/institute/institute_ledger_dropdown/?institute=${
        instituteId ? instituteId : ""
      }&search=${searchText ? searchText : ""}`
    )
  } else {
    return get(
      `/api/v1/institute/institute_ledger_dropdown/?search=${
        searchText ? searchText : ""
      }`
    )
  }
}
const createLedgerApi = ({ ledger }) => {
  return post("/api/v1/institute/institute_ledger/", ledger)
}
const updateLedgerApi = ({ ledgerId, ledger }) => {
  return patch(`/api/v1/institute/institute_ledger/${ledgerId}/`, ledger)
}
const deleteLedgerApi = ({ ledgerId }) => {
  return del(`/api/v1/institute/institute_ledger/${ledgerId}/`)
}

function* fetchLedgers({ payload }) {
  try {
    const response = yield call(getLedgersAPi, payload)
    if (response && !response?.error) {
      yield put(getLedgersSuccess(response))
    } else {
      yield put(getLedgersFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getLedgersFail(error))
  }
}

function* fetchLedgersForDownload({ payload }) {
  try {
    const response = yield call(getLedgersForDownloadAPi, payload)
    if (response && !response?.error) {
      yield put(getLedgersForDownloadSuccess(response))
    } else {
      yield put(getLedgersForDownloadFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getLedgersForDownloadFail(error))
  }
}

function* fetchLedgerDetail({ ledgerId }) {
  try {
    const response = yield call(getLedgerDetailsAPi, ledgerId)
    if (response && !response?.error) {
      yield put(getLedgerDetailSuccess(response))
    } else {
      yield put(getLedgerDetailFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getLedgerDetailFail(error))
  }
}

function* fetchLedgerDropdown({ payload }) {
  try {
    const response = yield call(getLedgerDropdownApi, payload)
    if (response && !response?.error) {
      yield put(getLedgerDropdownSuccess(response))
    } else {
      yield put(getLedgerDropdownFail(response))
      errorNotification(response?.error)
    }
  } catch (error) {
    yield put(getLedgerDropdownFail(error))
  }
}

function* onCreateLedger({ payload }) {
  try {
    const response = yield call(createLedgerApi, payload)
    if (response && !response?.error) {
      yield put(createLedgerSuccess(response))
      doneNotification("Created Successfully")
    } else {
      yield put(createLedgerFail(response))
      errorNotification(response?.error)
      // console.log("error: ", response?.error)
    }
  } catch (error) {
    yield put(createLedgerFail(error))
    errorNotification()
  }
}

function* onUpdateLedger({ payload }) {
  try {
    const response = yield call(updateLedgerApi, payload)
    if (response && !response?.error) {
      yield put(updateLedgerSuccess(response))
      // payload.history.push(`/ledgers/${response?.id}`)
      // try {
      //   const response2 = yield call(getLedgerDetailsAPi, response?.id)
      //   yield put(getLedgerDetailSuccess(response2))
      // } catch (error) {
      //   yield put(getLedgerDetailFail(error))
      // }
      doneNotification("Updated Successfully")
    } else {
      yield put(updateLedgerFail(response))
      errorNotification(response?.error)
      console.log("error: ", response?.error)
    }
  } catch (error) {
    yield put(updateLedgerFail(error))
    errorNotification()
  }
}

function* onDeleteLedger({ payload }) {
  try {
    const response = yield call(deleteLedgerApi, payload)
    if (response && !response?.error) {
      yield put(deleteLedgerSuccess(response))
      payload.history.goBack()
      doneNotification("Deleted Successfully")
    } else {
      yield put(deleteLedgerFail(response))
      errorNotification(response?.error)
      console.log("error: ", response?.error)
    }
  } catch (error) {
    errorNotification()
    yield put(deleteLedgerFail(error))
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}
function doneNotification(msg) {
  Notification({
    type: "success",
    message: msg ? msg : "Done",
    title: "",
  })
}

function* LedgersSaga() {
  yield takeEvery(GET_LEDGERS, fetchLedgers)
  yield takeEvery(GET_LEDGERS_FOR_DOWNLOAD, fetchLedgersForDownload)
  yield takeEvery(GET_LEDGER_DETAIL, fetchLedgerDetail)
  yield takeEvery(GET_LEDGER_DROPDOWN, fetchLedgerDropdown)
  yield takeEvery(CREATE_LEDGER, onCreateLedger)
  yield takeEvery(UPDATE_LEDGER, onUpdateLedger)
  yield takeEvery(DELETE_LEDGER, onDeleteLedger)
}

export default LedgersSaga
