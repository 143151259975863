import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { AvField, AvForm } from "availity-reactstrap-validation"
import propTypes from "prop-types"
import { Col, Modal, Button, Label, Row } from "reactstrap"
import { map } from "lodash"

import {
  updateLedger,
  deleteLedger,
  getLedgerDetail,
  getMahallTypeLists,
  clearMahallAccounts,
} from "store/actions"
import { handleSelectOrDeselect } from "hooks/useSelectOrDeselect"
import { errorNotification } from "components/Common/Notification"

const UiModalClearAccounts = ({ show, onCloseclick, data, history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const {
    loading,
    language,
    // instituteId,
  } = useSelector(state => ({
    loading: state.Mahalls.loading,
    language: state.Layout.language,
  }))

  const handleValidSubmit = (onSubmitProps, values) => {
    if(values.mahall_english_name == data.mahall_english_name){
    onCloseclick()
    dispatch(clearMahallAccounts(data.id, values.mahall_english_name))
    }else{
      errorNotification("Incorrect mahall name entered")
    }
  }



  return (
    <React.Fragment>
      <div>
        <Modal isOpen={show} toggle={onCloseclick} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              Clear Accounts
            </h5>
            <button
              type="button"
              onClick={onCloseclick}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="form-horizontal "
              onValidSubmit={(onSubmitProps, v) => {
                handleValidSubmit(onSubmitProps, v)
              }}
            >
              <div className="row mb-4">
                <Col>
                  <Label>
                   To Confirm, type &quot;<b> {data.mahall_english_name} </b>&quot; in the box below
                    {/* <span className="text-danger">&#42;</span> */}
                  </Label>
                  <AvField
                    name="mahall_english_name"
                    type="text"
                    value=""
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Col>
              </div>
         
              <Row className="mt-3 d-flex">
                <div className="col-6">
                  <div className="row justify-content-start">
                    <div>
                      {/* <Button
                        type="button"
                        color="danger"
                        className="w-md"
                        onClick={() => {
                          onCloseclick()
                          handleDeleteLedger(ledgerDetail?.id)
                        }}
                      >
                        {language === "mal" ? "ഡിലീറ്റ് ചെയ്യുക" : "Delete"}
                      </Button> */}
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row justify-content-end">
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="w-md m-auto me-0 d-block"
                      >
                        {loading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Row>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default UiModalClearAccounts
UiModalClearAccounts.propTypes = {
  onCloseclick: propTypes.func,
  show: propTypes.bool,
  data : propTypes.object,
  history: propTypes.object,
}
